<template>
  <ServiceAreaIndex
    :qso="qso"
    :endpoint="endpoint"
    :pageTitle="$t('components.serviceAreaManagement.headline')"
    :isShowOnlyPageTitle="true"
  />
</template>

<script>
import ServiceAreaIndex from '@/views/geofence/service-area/index.vue'
import { ServiceAreaConfig } from '@/config/ServiceAreaConfig'
export default {
  name: 'ViewOrganizationServiceArea',

  components: {
    ServiceAreaIndex,
  },
  data() {
    return {
      qso: { append: '', prepend: '&' },
      endpoint: null,
    }
  },
  created() {
    this.generateEndPoint()
  },

  methods: {
    generateEndPoint() {
      this.endpoint =
        ServiceAreaConfig.api.index + `?organization=${this.$route.params.id}`
    },
  },
}
</script>
