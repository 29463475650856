var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observerRef",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('loading',{attrs:{"active":_vm.isLoading,"is-full-page":false},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('form',{staticClass:"px-6",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitStep)}}},[_c('UploadAvatar',{class:"mb-3",attrs:{"current":_vm.form.avatar},model:{value:(_vm.form.avatar),callback:function ($$v) {_vm.$set(_vm.form, "avatar", $$v)},expression:"form.avatar"}}),_c('div',{staticClass:"grid grid-cols-2 gap-4"},[_c('AppInput',{attrs:{"type":"text","name":"name","label":_vm.$t(
            'components.organizationManagement.addEdit.steps.profile.title.name'
          ),"rules":"required","infoDescription":_vm.$t(
            'components.organizationManagement.addEdit.steps.profile.infoDescription.name'
          ),"placeholder":_vm.$t(
            'components.organizationManagement.addEdit.steps.profile.placeHolder.name'
          )},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('AppInput',{attrs:{"type":"tel","name":"phone","rules":"required","label":_vm.$t(
            'components.organizationManagement.addEdit.steps.profile.title.phoneNumber'
          ),"infoDescription":_vm.$t(
            'components.organizationManagement.addEdit.steps.profile.infoDescription.phoneNumber'
          ),"placeholder":_vm.$t(
            'components.organizationManagement.addEdit.steps.profile.placeHolder.phoneNumber'
          )},model:{value:(_vm.form.phone_number),callback:function ($$v) {_vm.$set(_vm.form, "phone_number", $$v)},expression:"form.phone_number"}})],1),_c('div',{staticClass:"grid grid-cols-2 gap-4"},[_c('AppInput',{attrs:{"type":"text","name":"alias","rules":"required","label":_vm.$t(
            'components.organizationManagement.addEdit.steps.profile.title.alias'
          ),"infoDescription":"Please provide an alternative or nickname of your organization.","placeholder":_vm.$t(
            'components.organizationManagement.addEdit.steps.profile.placeHolder.alias'
          )},model:{value:(_vm.form.alias),callback:function ($$v) {_vm.$set(_vm.form, "alias", $$v)},expression:"form.alias"}}),_c('AppInput',{attrs:{"type":"richselect","name":"country","label":_vm.$t(
            'components.organizationManagement.addEdit.steps.profile.title.country'
          ),"rules":"required","placeholder":_vm.$t(
            'components.organizationManagement.addEdit.steps.profile.placeHolder.country'
          ),"value-attribute":"id","text-attribute":"name","infoDescription":_vm.$t(
            'components.organizationManagement.addEdit.steps.profile.infoDescription.country'
          ),"options":_vm.countries},model:{value:(_vm.form.country),callback:function ($$v) {_vm.$set(_vm.form, "country", $$v)},expression:"form.country"}})],1),_c('div',{staticClass:"grid grid-cols-2 gap-4"},[_c('AppInput',{attrs:{"type":"richselect","name":"city","label":_vm.$t(
            'components.organizationManagement.addEdit.steps.profile.title.city'
          ),"rules":"required","disabled":_vm.getCityDisabledState,"placeholder":_vm.getCityPlaceholder,"infoDescription":_vm.$t(
            'components.organizationManagement.addEdit.steps.profile.infoDescription.city'
          ),"value-attribute":"id","text-attribute":"name","options":_vm.cities},model:{value:(_vm.form.city),callback:function ($$v) {_vm.$set(_vm.form, "city", $$v)},expression:"form.city"}}),_c('AppInput',{attrs:{"type":"text","name":"zip","label":_vm.$t(
            'components.organizationManagement.addEdit.steps.profile.title.zipCode'
          ),"rules":"required","placeholder":"e.g. 5020","infoDescription":_vm.$t(
            'components.organizationManagement.addEdit.steps.profile.infoDescription.zipCode'
          )},model:{value:(_vm.form.zip_code),callback:function ($$v) {_vm.$set(_vm.form, "zip_code", $$v)},expression:"form.zip_code"}})],1),_c('div',{staticClass:"grid grid-cols-2 gap-4"},[_c('AppInput',{attrs:{"type":"richselect","name":"fleet","rules":"","label":_vm.$t(
            'components.organizationManagement.addEdit.steps.profile.title.defaultFleet'
          ),"placeholder":_vm.$t(
            'components.organizationManagement.addEdit.steps.profile.placeHolder.defaultFleet'
          ),"fetch-options":_vm.onFetchFleets,"infoDescription":_vm.$t(
            'components.organizationManagement.addEdit.steps.profile.infoDescription.defaultFleet'
          ),"hide-search-box":""},model:{value:(_vm.form.default_fleet),callback:function ($$v) {_vm.$set(_vm.form, "default_fleet", $$v)},expression:"form.default_fleet"}}),_c('AppInput',{attrs:{"type":"richselect","name":"type","label":_vm.$t(
            'components.organizationManagement.addEdit.steps.profile.title.type'
          ),"rules":"required","placeholder":_vm.$t(
            'components.organizationManagement.addEdit.steps.profile.placeHolder.type'
          ),"text-attribute":"text","value-attribute":"value","infoDescription":_vm.$t(
            'components.organizationManagement.addEdit.steps.profile.infoDescription.type'
          ),"options":_vm.getOrganizationCategories,"hide-search-box":""},model:{value:(_vm.form.organization_category),callback:function ($$v) {_vm.$set(_vm.form, "organization_category", $$v)},expression:"form.organization_category"}})],1),_c('div',[_c('AppInput',{attrs:{"type":"textarea","name":"address","label":_vm.$t(
            'components.organizationManagement.addEdit.steps.profile.title.address'
          ),"rules":"required","placeholder":"","infoDescription":_vm.$t(
            'components.organizationManagement.addEdit.steps.profile.infoDescription.address'
          )},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}})],1),_c('div',{staticClass:"my-4 text-sm font-bold text-gray-400",domProps:{"textContent":_vm._s(
        _vm.$t(
          'components.organizationManagement.addEdit.steps.profile.subText.domain'
        )
      )}}),_c('div',{staticClass:"grid grid-cols-2 gap-4"},[_c('AppInput',{attrs:{"type":"text","name":"admin","label":_vm.$t(
            'components.organizationManagement.addEdit.steps.profile.title.adminDomain'
          ),"rules":"required","infoDescription":_vm.$t(
            'components.organizationManagement.addEdit.steps.profile.infoDescription.adminDomain'
          ),"placeholder":"e.g. www.admin.example.com"},model:{value:(_vm.form.admin_domain),callback:function ($$v) {_vm.$set(_vm.form, "admin_domain", $$v)},expression:"form.admin_domain"}}),_c('AppInput',{attrs:{"type":"text","name":"app","label":_vm.$t(
            'components.organizationManagement.addEdit.steps.profile.title.appDomain'
          ),"rules":"required","infoDescription":_vm.$t(
            'components.organizationManagement.addEdit.steps.profile.infoDescription.appDomain'
          ),"placeholder":"e.g. www.app.example.com"},model:{value:(_vm.form.domain),callback:function ($$v) {_vm.$set(_vm.form, "domain", $$v)},expression:"form.domain"}})],1),_c('button',{ref:"submitButton",staticClass:"hidden",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('components.stepNavigation.save'))+" ")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }