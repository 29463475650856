<template>
  <BillingPlan
    :qso="qso"
    :endpoint="endpoint"
    :pageTitle="$t('components.billingPlanManagement.headline')"
    :isShowOnlyPageTitle="true"
  />
</template>

<script>
import BillingPlan from '@/views/pricing/billing-plan/index.vue'
import { BillingPlanConfig } from '@/config/BillingPlanConfig'
export default {
  name: 'ViewOrganizationPricingPlan',

  components: {
    BillingPlan,
  },
  data() {
    return {
      qso: { append: '', prepend: '&' },
      endpoint: null,
    }
  },
  created() {
    this.generateEndPoint()
  },

  methods: {
    generateEndPoint() {
      this.endpoint =
        BillingPlanConfig.api.index + `?organization=${this.$route.params.id}`
    },
  },
}
</script>
