var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('loading',{attrs:{"active":_vm.isLoading,"is-full-page":false},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('form',{staticClass:"px-6",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitStep)}}},[_c('section',[_c('div',{staticClass:"grid grid-cols-2 gap-4"},[_c('AppInput',{attrs:{"type":"text","name":"Full Name","label":_vm.$t(
                'components.organizationManagement.addEdit.steps.Owner.title.fullName'
              ),"rules":"required","infoDescription":_vm.$t(
                'components.organizationManagement.addEdit.steps.Owner.infoDescription.fullName'
              ),"placeholder":"e.g. John Doe"},model:{value:(_vm.form.full_name),callback:function ($$v) {_vm.$set(_vm.form, "full_name", $$v)},expression:"form.full_name"}}),_c('AppInput',{attrs:{"type":"text","name":"Username","label":_vm.$t(
                'components.organizationManagement.addEdit.steps.Owner.title.username'
              ),"rules":"required","infoDescription":_vm.$t(
                'components.organizationManagement.addEdit.steps.Owner.infoDescription.username'
              ),"placeholder":"e.g. john_doe ","disabled":!_vm.isSuperAdmin},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}})],1),_c('div',{staticClass:"grid grid-cols-2 gap-4"},[_c('AppInput',{attrs:{"type":"tel","name":"Phone Number","rules":"required","label":_vm.$t(
                'components.organizationManagement.addEdit.steps.Owner.title.phoneNumber'
              ),"infoDescription":_vm.$t(
                'components.organizationManagement.addEdit.steps.Owner.infoDescription.phoneNumber'
              ),"placeholder":_vm.$t(
                'components.organizationManagement.addEdit.steps.Owner.placeHolder.phoneNumber'
              ),"haveSuggestion":true,"isSuggestionAlert":_vm.isPhoneNumberExist,"suggestionNonAlertClass":_vm.isPhoneNumberChecking
                ? "text-gray-700 font-semibold"
                : "text-green-500 font-semibold","suggestionText":_vm.suggestionsForPhoneNumber},on:{"input":_vm.onChangePhoneNumber,"country-changed":_vm.onCountryChanged},model:{value:(_vm.form.phone_number),callback:function ($$v) {_vm.$set(_vm.form, "phone_number", $$v)},expression:"form.phone_number"}}),_c('AppInput',{attrs:{"type":"email","name":"Email","rules":"required","label":_vm.$t(
                'components.organizationManagement.addEdit.steps.Owner.title.email'
              ),"infoDescription":_vm.$t(
                'components.organizationManagement.addEdit.steps.Owner.infoDescription.email'
              ),"placeholder":"e.g. john@example.com","haveSuggestion":true,"isSuggestionAlert":_vm.isEmailExist,"suggestionNonAlertClass":_vm.isEmailChecking
                ? "text-gray-700 font-semibold"
                : "text-green-500 font-semibold","suggestionText":_vm.suggestionsForEmail},on:{"input":_vm.onChangeEmail},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1),(_vm.isSuperAdmin)?_c('div',{staticClass:"grid grid-cols-2 gap-4"},[_c('AppInput',{attrs:{"type":"password","name":"password","rules":_vm.getPasswordValidationRules,"infoDescription":_vm.$t(
                'components.organizationManagement.addEdit.steps.Owner.infoDescription.password'
              ),"label":_vm.$t(
                'components.organizationManagement.addEdit.steps.Owner.title.password'
              ),"placeholder":""},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('AppInput',{attrs:{"type":"password","name":"Confirm Password","infoDescription":_vm.$t(
                'components.organizationManagement.addEdit.steps.Owner.infoDescription.confirmPassword'
              ),"rules":"confirmed:password","label":_vm.$t(
                'components.organizationManagement.addEdit.steps.Owner.title.confirmPassword'
              ),"placeholder":""},model:{value:(_vm.passwordConfirmation),callback:function ($$v) {_vm.passwordConfirmation=$$v},expression:"passwordConfirmation"}})],1):_vm._e(),_c('div',{staticClass:"grid grid-cols-2 gap-4"},[_c('AppInput',{attrs:{"type":"date","name":"Owner Expiry Date","label":_vm.$t(
                'components.organizationManagement.addEdit.steps.Owner.title.ownerExpiryDate'
              ),"infoDescription":_vm.$t(
                'components.organizationManagement.addEdit.steps.Owner.infoDescription.ownerExpiryDate'
              ),"placeholder":""},model:{value:(_vm.form.owner_expiry_date),callback:function ($$v) {_vm.$set(_vm.form, "owner_expiry_date", $$v)},expression:"form.owner_expiry_date"}})],1)]),_c('button',{ref:"submitButton",staticClass:"hidden",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('components.stepNavigation.save'))+" ")])])]}}])}),_c('t-modal',{ref:"modalRef",attrs:{"name":"ownerOtp","variant":"confirmation","hideCloseButton":true,"disableBodyScroll":true}},[_c('header',{staticClass:"py-4 px-3"},[_c('div',{staticClass:"flex justify-between modal-header"},[_c('div',{staticClass:"w-full text-left"},[_c('div',{staticClass:"px-4 text-2xl font-extrabold text-center"},[_vm._v(" "+_vm._s(_vm.$t( 'components.organizationManagement.addEdit.steps.Owner.text.text1' ))+" ")]),_c('div',{staticClass:"px-4 font-bold text-center"},[_vm._v(" "+_vm._s(_vm.$t( 'components.organizationManagement.addEdit.steps.Owner.text.text2' ))+" "+_vm._s(_vm.getOtpModalSubtitlePart)+" "+_vm._s(_vm.$t( 'components.organizationManagement.addEdit.steps.Owner.text.text3' ))+" ")])])])]),_c('section',{staticClass:"w-full h-50"},[_c('div',{staticClass:"grid justify-center"},[_c('AppInput',{staticClass:"justify-center",attrs:{"type":"number","name":"","label":"","placeholder":"e.g. 876540"},model:{value:(_vm.otpInput),callback:function ($$v) {_vm.otpInput=$$v},expression:"otpInput"}})],1)]),_c('div',{staticClass:"flex gap-4 justify-end py-2 px-4 bg-white modal-header"},[_c('AppButton',{attrs:{"text":'Cancel',"variant":'secondary'},on:{"click":function($event){return _vm.handleOtpCancel()}}}),_c('AppButton',{attrs:{"text":'Confirm',"variant":'primary'},on:{"click":function($event){return _vm.handleOtpSubmit()}}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }